.footer-frame {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "copyright"
    "facebook"
    "privacy";
}
.copyright {
  grid-column: 0;
}
.privacy {
  grid-column: 2;
}
.lta-logo{
  height: 40px;
  grid-column: 3;
}

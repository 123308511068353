.page-playing {
  text-align: center;
  display: block;
}

.table-playing {
  width: 30vw;
}
.table-date {
  width: 30vw;
}



.topnav {
  display: grid;
  grid-template-columns: 1fr 9fr;
  background-color: var(--color-1);
  width: 100vw;
}
.topnav a:hover {
  color: var(--color-2);
}
.log-in-out {
  font-size: larger;
  border: none;
  height: 35px;
  color: var(--color-3);
  background-color: var(--color-1);
  display: inline;
  padding-right: 10px;
}
.form-log-in-out {
  min-width: 90vw;
  top: 25vh;
  left: 5vw;
  background-color: azure;
  border-color: var(--color-1);
  border-radius: 10px;
}
.form-pre-pw-change {
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}
.log-header {
  padding-left: 10px;
  padding-bottom: 25px;
  display: grid;
  grid-template-columns: 9fr 1fr;
}
.btn-pre-change-pw {
  width: 50%;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 25px;
  margin-top: 25px;
  margin-left: 25%;
}
.text-field-group-input {
  min-width: 50vw;
}
.log-form-content {
  text-align: left;
  padding-left: 2rem;
  padding-bottom: 10px;
}
.log-btn-bar {
  margin-top: 5px;
}
.log-btn {
  padding: 5px;
  margin-right: 5px;
}

.btn-close {
  margin: 5px;
}
.icon-pw {
  padding-left: 5px;
  cursor: pointer;
}
.log-hints {
  text-align: end;
  margin-right: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
  text-decoration-line: underline;
  cursor: pointer;
}

.burger {
  text-align: right;
  color: var(--color-3);
  padding-right: 50px;
  padding-top: 50px;
}
.burger:hover {
  color: var(--color-2);
}
.logo {
  width: 100px;
  display: inline-block;
}
.topnav a {
  display: block;
  color: var(--color-3);
  font-size: 17px;
}

.sub-menu {
  min-width: 50vw;
}
.hidden {
  display: none;
}
@media screen and (min-width: 600px) {
  .form-log-in-out {
    min-width: 50vw;
    left: 25vw;
  }
  .input-text-field-group {
    width: 40vw;
  }
  .text-field-group-input {
    min-width: 30vw;
  }
}

@media screen and (min-width: 1000px) {
  .burger {
    display: none;
  }
  .sub-menu {
    min-width: 50vw;
    display: grid;
    grid-auto-flow: column;
    padding-top: 20px;
  }
  .topnav {
    overflow: hidden;
  }
  .topnav a {
    display: inline;
  }
  .form-log-in-out {
    min-width: 30vw;
    left: 35vw;
  }
  .input-text-field-group {
    width: 20vw;
  }
}
.text-field-group-input {
  min-width: 15vw;
}

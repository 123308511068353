:root {
  --color-1:  rgb(4, 71, 39);
  --color-2:white;
  --color-3:#E0E31A
}
.page {
  text-align: center;
  display: block;
}

.site-grid {
  padding: 8px;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr 50px;
    grid-template-areas:
    "header"
    "main"
    "footer";
}
header {
  grid-area: header;
}
aside {
  grid-area: sidebar;
}
main {
  grid-area: main;
}
footer {
  grid-area: footer;
}

.site-style {
  /* background-image: linear-gradient(var(--color-1), var(--color-2)); */
  background-color: var(--color-2);
  height: 100vh;
  color: var(--color-1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.head{
  font-size: x-large;
      width: 90vw;    
  }
  .strap{
      font-size: large;
      width: 90vw;  
  }
  .main-img{
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 90vw;
    max-height: 30vh;
    padding-right: 10vw;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.text-content{
  text-align: left;
  width:90vw;
  padding-left: 5vw;
}

/* Table Styles */
.table-event {
  width: 30vw;
}
.table-date {
  width: 30vw;
}

.table-head{
    font-weight: bold;
}
.table-row{
margin-top: 50px;
}
.table-main{
margin-left: 5vw;
margin-right: 15vw;
}
td{
    padding-bottom: 5px;
    padding-top: 10px;
    padding-left: 10px;
}
table {
  border-collapse:collapse;
}
tr {
  border-bottom: 1px solid #ddd;
}
/* Font Awesome Styles */
.fa-facebook {
  background: #0866FF;
  color: white;
}
.fa {
  padding: 5px;
  font-size: 20px;
  width: 30px;
  height: 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}

@media screen and (min-width: 1000px) {
  .text-content, .email, .contact{
      width: 80vw;
      padding-left: 10vw;       
  }
}

 .page-contact{
    text-align: center;
    display: block;
}

.contact-strap{
    padding-left: 25px;
    font-size: large;
    width: 90vw;
    text-align: left;
}
  .contact-map{
    padding: 25px;
    width: 90vw;
    height: 50vh;
  }
  .contact-text{
    padding: 25px;
    text-align: left;
  }
  .contact-email{
    padding-left: 25px;
    text-align: start;
    display: block;
}
.page-events {
  text-align: center;
  display: block;
}

.sign-icon {
    width: 20vw;
    color: blue;
}
.event-list-item{
margin-left: 25vw;
  width: 50vw;
  border: 1px solid;
  list-style-type: none;
}
.dialog-event{
  height: 200px;
  width: 300px;
}